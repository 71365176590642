import React from "react";

import { Divider, List } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";

export const ThermoProtectFichaTecnica = () => {
  const data = [
    {
      content: (
        <>
          <b className="paragraphTitle">PREPARACIÓN DE SUPERFICIES</b>
          <ul>
            <li>
              Eliminación de materiales sueltos o que puedan interferir en la
              adherencia: en general, en cualquier superficie con restos de
              pintura o materiales sueltos, se deberá sanear/lijar mecánicamente
              o aplicar agua a presión para retirar cualquier material suelto
              que pueda interferir en la adherencia, hasta obtener un soporte
              firme.
            </li>
            <li>
              Limpieza: los soportes deben limpiarse debidamente, utilizando
              agua a presión u otro sistema, hasta obtener un soporte limpio.
            </li>
            <li>
              Soportes de carácter mineral (absorbentes), como tejas cerámicas o
              de pizarra: aplicar imprimación Fix Plus (seguir instrucciones del
              manual de aplicación).
            </li>
            <li>
              Soportes de material sintéticos: analizar la capacidad de
              adherencia. La adherencia será buena incluso en superficies lisas.
              No obstante, si se quiere mejorar la adherencia sobre una
              superficie lisa (ej: PVC), se puede realizar un lijado manual
              suave previo para abrir el poro del soporte.
            </li>
            <li>
              Metales pintados: en caso que la pintura existente esté en buen
              estado, se realiza la aplicación directamente (tras la limpieza
              previa).
            </li>
            <li>
              Metales oxidados: lijar o sanear mecánicamente hasta eliminar el
              óxido completamente, y aplicar imprimación antioxidante, o
              pasivador de óxido, según el grado de oxidación.
            </li>
            <li>
              Metales ferrosos en bruto: aplicar imprimación antioxidante.
            </li>
            <li>
              Soportes de zinc y metales galvanizados: aplicar imprimación
              ZincPrimer (seguir instrucciones del manual de aplicación).
            </li>
            <li>Acero inoxidable: aplicar directamente.</li>
          </ul>
          <b className="paragraphTitle">APLICACIÓN</b>
          El producto se puede aplicar mediante brocha, rodillo o método de
          pulverización con pistola airless. La aplicación se realiza en dos
          capas (dos manos).
          <b className="paragraphTitle">Aplicación de la primera mano:</b>
          <ul>
            <li>
              {" "}
              Remover muy bien el material del envase, preferiblemente con
              batidor mecánico o, en su defecto, mediante espátula ancha.
            </li>
            <li>
              {" "}
              En caso de soportes porosos como teja de pizarra, se recomienda
              añadir un 5% de agua al material en la primera mano (625ml de agua
              en un envase de 12,5 litros), removiendo muy bien hasta que el
              producto esté perfectamente mezclado.
            </li>
            <li>
              {" "}
              Aplicar el producto y, en caso de aplicar con brocha o rodillo,
              peinar abundantemente en ambas direcciones.
            </li>
            <li> Dejar secar al tacto, preferiblemente durante 12 horas.</li>
          </ul>
        </>
      ),
    },
    {
      content: (
        <>
          <b className="paragraphTitle"> Aplicación de la segunda mano:</b>
          <ul>
            <li>
              {" "}
              Remover muy bien el material del envase, preferiblemente con
              batidor mecánico o, en su defecto, mediante espátula ancha.
            </li>
            <li>
              {" "}
              Aplicar el producto sin diluir y, en caso de aplicar con brocha o
              rodillo, peinar abundantemente en ambas direcciones.
            </li>
            <li> Dejar secar al tacto, preferiblemente durante 12 horas.</li>
          </ul>
          <p>
            Nota respecto a aplicación mediante pistola airless: En caso de
            aplicación mediante equipos de pulverización, se deben seguir las
            instrucciones del fabricante del equipo. Un diámetro de 0.43 a
            0.48mm de paso en la boquilla y presión de 80 a 85 bar se considera
            adecuado. No obstante, se recomienda hacer una prueba en obra. La
            consistencia del producto se puede regular añadiendo una pequeña
            cantidad de agua (aprox. 5%).
          </p>
          <b className="paragraphTitle">RENDIMIENTO</b>
          <p>
            El rendimiento a aplicar depende de la porosidad del soporte, con un
            mínimo de 330 ml/m2 (equivale a 1 litro/3m2) calculado para dos
            capas, sobre superficies lisas de baja porosidad. La capacidad de
            esparcimiento variará según la estructura y la porosidad de la
            superficie. En caso de duda, primero se debe aplicar sobre un área
            de prueba pequeña. Se debe observar que se cumple el rendimiento
            mínimo indicado. En caso de no extender el material necesario entre
            las dos primeras manos, se deberá agotar el material previsto en una
            tercera mano. Si no se cumple la proporción indicada, el material no
            podrá desarrollar adecuadamente sus propiedades. Si se realiza la
            aplicación mediante pistola airless, deberá tenerse en cuenta la
            pérdida adicional del material, por lo que puede ser necesaria una
            proporción de rendimiento superior a la indicada. El producto
            requiere aproximadamente 20 días para desarollar sus propiedades
            técnicas.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="linkBack">
        <Link to="/productos/thermoprotect/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a ThermoProtect</span>
        </Link>
      </div>{" "}
      <div className="productDocHeader">
        <h1 className="centered">Ficha Técnica ThermoProtect</h1>
      </div>
      <Divider />
      <div style={{ marginBottom: "50px" }}>
        <List
          grid={{ gutter: 24, column: 2 }}
          dataSource={data}
          renderItem={({ content }) => <List.Item>{content}</List.Item>}
        />
      </div>
    </>
  );
};

export default ThermoProtectFichaTecnica;
