import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";

import "src/components/products/style.scss";
import "src/components/products/thermoProtect/style.scss";
import ThermoProtectFichaAplicacion from "src/components/products/thermoProtect/FichaAplicacion";

const ThermoProtect = (props) => (
  <LayoutGeneral
    {...{ ...props, productName: "ThermoProtect", showPageTitle: false }}
  >
    <Content className="product thermoProtect fichaAplicacion">
      <ThermoProtectFichaAplicacion />
    </Content>
  </LayoutGeneral>
);

export default ThermoProtect;
